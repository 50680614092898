import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'spinner' ]

  showProgress(e) {
    this.spinnerTarget.classList.remove('hidden');
  }

  submit(e) {
    e.target.closest('form').requestSubmit();
  }
}
