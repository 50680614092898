import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'form', 'wrapper' ]

  initialize() {
    this.elementBeingDragged = null;
  }

  connect() {
    this.dropzoneElement = document.createElement("div");
    this.dropzoneElement.addEventListener('dragenter', this.preventDefault);
    this.dropzoneElement.addEventListener('dragover', this.preventDefault);
    this.dropzoneElement.addEventListener('drop', this.drop.bind(this));
  }

  dragStart(event) {
    let el = event.target.closest('div[draggable]');
    el.style.opacity = '0.4';
    this.elementBeingDragged = el;
  }

  dragEnd(event) {
    let el = event.target.closest('div[draggable]');
    el.style.opacity = '1';
    this.dropzoneElement.classList.add('hidden');
  }

  dragEnter(e) {
    e.preventDefault();

    let el = event.target.closest('div[draggable]');
    if (el !== this.elementBeingDragged) {
      this.dropzoneElement.classList = el.classList;
      this.dropzoneElement.classList.add('border', 'border-dashed');
      this.dropzoneElement.style.height = this.elementBeingDragged.offsetHeight + 'px';
      this.dropzoneElement.style.borderWidth = '4px';
      this.wrapperTarget.insertBefore(this.dropzoneElement, el)
    }
  }

  dragOver(e) {
    e.preventDefault();
  }

  drop(event) {
    event.stopPropagation();
    this.wrapperTarget.insertBefore(this.elementBeingDragged, this.dropzoneElement)
    this.wrapperTarget.style.opacity = '0.4';
    this.formTarget.requestSubmit();
  }

  preventDefault(e) {
    e.preventDefault()
  }
}
