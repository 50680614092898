import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "form",
    "shippingAddressForm",
    "shippingAddressSwitch",
    "summaryForm"
  ]

  connect() {
    this.toggleShippingAddressForm()
  }

  toggleShippingAddressForm() {
    if (!this.shippingAddressSwitchTarget.checked) {
      this.shippingAddressFormTarget.classList.add("hidden")
    } else {
      this.shippingAddressFormTarget.classList.remove("hidden")
    }
  }

  refreshOverview() {
    const shippingMethod = this.formTarget.querySelector('input[name="order[shipping_method]"]:checked').value
    const billingMethod = this.formTarget.querySelector('input[name="order[billing_method]"]:checked').value
    this.summaryFormTarget.querySelector('input[name="order[shipping_method]"]').value = shippingMethod
    this.summaryFormTarget.querySelector('input[name="order[billing_method]"]').value = billingMethod
    this.summaryFormTarget.requestSubmit()
  }
}
