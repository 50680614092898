import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'input', 'priceWithVat' ];

  connect() {
    this.displayPriceWithVat();
    this.inputTarget.addEventListener('keyup', this.displayPriceWithVat.bind(this));
  }

  displayPriceWithVat() {
    let price = this.inputTarget.value;
    if (price) {
      let priceWithVat = Math.round(price * 100 * 1.25) / 100
      this.priceWithVatTarget.innerHTML = `${price} EUR + PDV = ${priceWithVat} EUR`;
      this.priceWithVatTarget.style.display = "block";
    } else {
      this.priceWithVatTarget.style.display = "none";
    }
  }
}
