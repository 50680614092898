import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["advancedWrapper", "simpleWrapper"]

  connect() {
    this.advancedWrapperTarget.style.display = "none"
    this.open()
  }

  open() {
    if (!this.element.open) {
      this.element.showModal()
    }
  }

  showAdvanced(event) {
    event.preventDefault()
    this.simpleWrapperTarget.style.display = "none"
    this.advancedWrapperTarget.style.display = "block"
  }

  hideAdvanced(event) {
    event.preventDefault()
    this.advancedWrapperTarget.style.display = "none"
    this.simpleWrapperTarget.style.display = "block"
  }
}
