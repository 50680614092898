import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'collapsedWrapper' ];

  collapse() {
    this.collapsedWrapperTarget.classList.add('hidden')
  }

  expand() {
    this.collapsedWrapperTarget.classList.remove('hidden')
  }
}
